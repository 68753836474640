<template>
  <div class="main-padding main-box-shadow">
    <h2>
      Privacy Policy
    </h2>
    <p>No personal data is stored.</p>
  </div>
</template>

<script>

</script>

<style></style>
