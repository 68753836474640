<template>
  <p>UNICODE, INC. LICENSE AGREEMENT - DATA FILES AND SOFTWARE</p>
  <p></p>
  <p>
    See Terms of Use for definitions of Unicode Inc.'ata Files and Software.
  </p>
  <p></p>
  <p>
    NOTICE TO USER: Carefully read the following legal agreementY DOWNLOADING,
    INSTALLING, COPYING OR OTHERWISE USING UNICODE INC.'ATA FILES ("DATA
    FILES"), AND/OR SOFTWARE ("SOFTWARE")OU UNEQUIVOCALLY ACCEPT, AND AGREE TO
    BE BOUND BY, ALL OF THERMS AND CONDITIONS OF THIS AGREEMENTF YOU DO NOT
    AGREE, DO NOT DOWNLOAD, INSTALL, COPY, DISTRIBUTE OR USHE DATA FILES OR
    SOFTWARE.
  </p>
  <p></p>
  <p>COPYRIGHT AND PERMISSION NOTICE</p>
  <p></p>
  <p>
    Copyright © 1991-2021 Unicode, Inc. All rights reservedistributed under the
    Terms of Use in https://www.unicode.org/copyright.html.
  </p>
  <p></p>
  <p>
    Permission is hereby granted, free of charge, to any person obtainin copy of
    the Unicode data files and any associated documentatiothe "Data Files") or
    Unicode software and any associated documentatiothe "Software") to deal in
    the Data Files or Softwarithout restriction, including without limitation
    the rights to useopy, modify, merge, publish, distribute, and/or sell copies
    ohe Data Files or Software, and to permit persons to whom the Data Filer
    Software are furnished to do so, provided that eithea) this copyright and
    permission notice appear with all copief the Data Files or Software, ob)
    this copyright and permission notice appear in associateocumentation.
  </p>
  <p></p>
  <p>
    THE DATA FILES AND SOFTWARE ARE PROVIDED "AS IS", WITHOUT WARRANTY ONY KIND,
    EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE ANONINFRINGEMENT OF THIRD
    PARTY RIGHTSN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN
    THIOTICE BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR
    CONSEQUENTIAAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USEATA
    OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHEORTIOUS
    ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OERFORMANCE OF THE DATA
    FILES OR SOFTWARE.
  </p>
  <p></p>
  <p>
    Except as contained in this notice, the name of a copyright holdehall not be
    used in advertising or otherwise to promote the salese or other dealings in
    these Data Files or Software without prioritten authorization of the
    copyright holder.
  </p>
</template>

<script>
export default {};
</script>

<style></style>
